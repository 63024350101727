import { Avatar, Popover, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api'
import img2 from '../../assets/noProject.png'
import { getMenus } from '../../store/actions/login'
import { getDeploy } from '../../utils/cookie'
import styles from './index.module.scss'
export default function NoSubScribe() {
  document.title = '无订阅'
  const [admins, setAdmins] = useState([])
  const dispatch = useDispatch()
  const getAdminFunc = () => {
    api.getAdmin().then((res) => {
      console.log(res)
      if (res.data?.code === 0) {
        setAdmins(res.data.data)
      } else {
        message.error(res.data.msg)
        setAdmins([])
      }
    })
  }

  const getMenusFn = async () => {
    api.getMenu().then((res) => {
      const findItem = res.data.data?.find((i) => i.menu_url)
      if (findItem) {
        // window.location.href = findItem.menu_url
      } else {
        return
      }
    })
  }

  useEffect(() => {
    getAdminFunc()
    // getMenusFn()
    // dispatch(getMenus())
  }, [])

  const userInfo = useSelector((state) => state.login.userInfo)
  return (
    <div className={styles['root']}>
      <div className="content">
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 20 }}>
            <img src={img2} alt="" />
          </div>
          <div>
            <p className="text">还未开通产品服务！</p>
            {userInfo?.is_admin || userInfo?.is_creator ? (
              <p className="text_bottom">
                请
                <a
                  onClick={() => {
                    if (getDeploy() === 'BOX') {
                      return
                    }
                    window.open(localStorage.getItem('sub_url'), 'sub_url')
                  }}
                >
                  联系商务
                </a>
                开通产品服务！
              </p>
            ) : (
              <p className="text_bottom">
                请联系
                <Popover
                  placement="bottomLeft"
                  title={null}
                  // visible={true}
                  overlayClassName="headerAdminPopover"
                  content={
                    <div className="adminPopoverContent">
                      {(admins || [])?.map((item) => (
                        <div className="adminPopoverItem">
                          <span>
                            <Avatar
                              style={{ marginRight: '8px' }}
                              size={24}
                              src={item?.pf_photo}
                            />
                            {item?.show_name}
                          </span>
                          <span>{item?.assist_name}</span>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <a className="adminBox"> 管理员 </a>
                </Popover>
                开通产品服务！
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
