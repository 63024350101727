import { Empty, Breadcrumb, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { MyIcon } from "../../../routers";
import api from "../../../api";
import img from "../../../assets/暂无icon@2x.png";
import SubCard from "./SubCard";
import moreImg from "../../../assets/更多产品.png";
import { initUrl } from "../../../utils";
import { getAdmin, getCreator } from "../../../utils/cookie";
import { getLocationOrigin } from "../../../utils/util";
export default function ProductSubscription() {
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [subsPlan, setSubsPlan] = useState([]);

  const getSubsPlan = () => {
    api.getSubsPlan().then((res) => {
      console.log("getSubsPlan", res);
      if (res.data?.code === 0) {
        setSubsPlan(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setSubsPlan([]);
      }
    });
  };

  const getProducts = () => {
    api.getProducts().then((res) => {
      if (res.data?.code === 0) {
        setProducts(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setProducts([]);
      }
    });
  };

  useEffect(() => {
    getSubsPlan();
    getProducts();
  }, []);

  const userInfo = useSelector((state) => state.login.userInfo);

  const btnCallBack = (info) => {
    let href = initUrl(info?.cs_url, info.prd_name, userInfo);
    window.open(href, "up");
  };

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>产品订阅</Breadcrumb.Item>
        </Breadcrumb>

        <div className="contanier">
          <div className="co_text">
            {userInfo?.co_type_code === "INDIV_ORG"
              ? userInfo?.show_name
              : userInfo?.co_name}
            <MyIcon
              type="icon-a-lujing1"
              style={{ fontSize: 14, marginLeft: 8 }}
            />
          </div>

          <div className="cardListBox">
            {/* {subsPlan?.length === 0 ? (
              <div className="noDataBox">
                <Empty image={img} description="暂无数据" />
              </div>
            ) : (
              <div className="cardsBox">
                {subsPlan?.map((item, index) => (
                  <SubCard
                    key={index}
                    info={item}
                    style={{ height: "calc(100% - 20px)" }}
                    max={4}
                  ></SubCard>
                ))}
              </div>
            )} */}
            <div className="cardsBox">
              {subsPlan?.map((item, index) => (
                <SubCard
                  key={index}
                  info={item}
                  style={{ height: "calc(100% - 20px)" }}
                  max={4}
                  btnCallBack={btnCallBack}
                  noBtn={!(getCreator() || getAdmin())}
                ></SubCard>
              ))}
              {getCreator() || getAdmin() ? (
                <div
                  className="addCard"
                  onClick={() => {
                    window.open(
                      getLocationOrigin() + "/saas/guidePage",
                      "_more"
                    );
                  }}
                >
                  <img src={moreImg}></img>
                  <span>更多产品</span>
                </div>
              ) : null}
            </div>
          </div>

          {products?.length > 0 && <div className="productTitle">为您推荐</div>}
          {products?.length > 0 && (
            <div className="productListBox">
              {products?.map?.((item, index) => (
                <div className="productCard" key={index}>
                  <img src={item.icon} alt="" />
                  <div
                    className="productCardBtn"
                    onClick={() => {
                      window.open(item.product_home, "_details");
                    }}
                  >
                    查看详情
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
